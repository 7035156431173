import React from "react"
import useFetch from "use-http" // { Provider } 
//import { toCamel } from 'convert-keys'

const APIEndPoint = 'http://www.randomnumberapi.com/api/v1.0/random?min=100&max=1000&count=1'

function APIResult() {

        const { loading, error, data } = useFetch(APIEndPoint, { data: [] }, []) // onMount (GET by default)
        console.log('data', data)
        return (
            <>
                {error && error.messge}
                {loading && "Loading..."}
                {data && <pre>{JSON.stringify(data, "array", 2)}</pre>}
            </>
        )
    /*
    const App = () => {
        const globalOptions = {
            interceptors: {
        request: ({ options }) => {
            options.headers = {
                        Authorization: 'Bearer YOUR_AUTH_TOKEN'
                    }
            return options
        },
        response: ({ response }) => {
                    console.log('initial resopnse.data', response.data)
                    response.data = toCamel(response.data)
            return response 
        }
        }
        }
        return (
            <Provider options={globalOptions}>
            </Provider>
        )
    }
    */
}
export default APIResult;
