import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";

import "survey-core/defaultV2.css";
import "../index.css";
import { json } from "../Surveys/alz.js"

StylesManager.applyTheme("defaultV2");

function SurveyComponent() {
  const survey = new Model(json);

  return (
    //<div className="container">
      <Survey
        model={survey}
        onComplete={onComplete}
        onValueChanged={onValueChanged}
      />
   // </div>
  );
}

function onValueChanged(_, options) {
  console.log("New value: " + options.value);
}

function onComplete(survey) {
  return "Survey complete! Results: " + JSON.stringify(survey.data);
}

export default SurveyComponent;
