import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron';
import Table from 'react-bootstrap/Table';
import APIResult from './APIResult';

function AboutThis() {
    const result = <APIResult />
    return (
        <>
            <Jumbotron>
                <div className="row">
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6">
                    <p>Visitor Count: {result}</p>
                    <p>- Currently not accurate</p>
                    </div>
                </div>
                <div className="App">
                    
                    
                </div>
            </Jumbotron>
            
                <h1>About this site</h1>
                <p>
                    This website is hosted on static web pages written in react hosted on cloud storage buckets in Azure, AWS, and soon GCP.
                    Each storage account is being served up using the respective cloud's CDN. All traffic to each cloud CDN is load balanced via a cloud flare load balancer.
                    There is only one react code base for the static website.
                </p>
                <div className="diagram" style={{width: '100%', height: '480px', margin: '20px', position: 'relative', align: 'center'}}>
                    <iframe title="diagram" allowfullscreen frameborder="0" style={{width: '800px', height: '480px'}} src="https://lucid.app/documents/embedded/27eb0316-eb90-4e94-8955-00ed474762d8" id="oiPYLuB55Xq2"></iframe>
                </div>
                <h2>TL;DR</h2>
                <p>
                    The react code base is built in a github actions pipeline and placed in to an artifact. Each cloud deployment happens simultaneously after the artifact is ready. The infrastructure is then deployed through Terraform via Terraform cloud triggered from the pipeline.
                    Once Terraform cloud is finished the github pipeline pushes the downloaded artifact to the respective storage accounts. You can find the code <a href="https://gist.github.com/73dward5/be06934c2e1564943a3ff51fe824837a">here.</a>
                </p>
            
            
            <Jumbotron>
                <h1>Links</h1>
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>Location</th>
                            <th>Site</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Load Balancer</td>
                            <td><a href="https://cloud.cloudlab-azure.com">cloudlab-azure.com</a></td>
                        </tr>
                        <tr>
                            <td>Azure CDN</td>
                            <td><a href="https://blob.cloudlab-azure.com">blob.cloudlab-azure.com</a></td>
                        </tr>
                        <tr>
                            <td>AWS CDN</td>
                            <td><a href="https://s3.cloudlab-aws.com">s3.cloudlab-aws.com</a></td>
                        </tr>
                        <tr>
                            <td>GCP Cloud Run</td>
                            <td><a href="https://cloud-run.cloudlab-gcp.com">cloud-run.cloudlab-gcp.com</a></td>
                        </tr>
                    </tbody>
                </Table>
            </Jumbotron>       
        </>
    )
}



export default AboutThis;