export const json = {
    "completedHtml": `<h3>Results</h3> 
    <pre>
    deployment_settings = {data}
    region = "{region}"
    root_id = "{root_id}"
    single_subscription = "{single_subscription}"
    log_analytics_enabled = "{log_analytics_enabled}"
    log_analytics_retention = "{log_analytics_retention}"
    management_group_id = "{management_group_id}"
    </pre>`,
    "logoPosition": "right",
    "showProgressBar": "top",
    "progressBarType": "buttons",
    "startWithNewLine": false,
    "showQuestionNumbers": "off",
    "pages": [
        {
            "name": "Deployment Settings",
            "elements": [
                {
                    "type": "dropdown",
                    "name": "region",
                    "hideNumber": true,
                    "title": "Region",
                    "choices": [
                        {
                            "value": "eastus",
                            "text": "East US"
                        },
                        {
                            "value": "eastus2",
                            "text": "East US 2"
                        },
                        {
                            "value": "southcentralus",
                            "text": "South Central US"
                        },
                        {
                            "value": "westus2",
                            "text": "West US 2"
                        },
                        {
                            "value": "westus3",
                            "text": "West US 3"
                        },
                        {
                            "value": "australiaeast",
                            "text": "Australia East"
                        },
                        {
                            "value": "southeastasia",
                            "text": "Southeast Asia"
                        },
                        {
                            "value": "northeurope",
                            "text": "North Europe"
                        },
                        {
                            "value": "swedencentral",
                            "text": "Sweden Central"
                        },
                        {
                            "value": "uksouth",
                            "text": "UK South"
                        },
                        {
                            "value": "westeurope",
                            "text": "West Europe"
                        },
                        {
                            "value": "centralus",
                            "text": "Central US"
                        },
                        {
                            "value": "northcentralus",
                            "text": "North Central US"
                        },
                        {
                            "value": "westus",
                            "text": "West US"
                        },
                        {
                            "value": "southafricanorth",
                            "text": "South Africa North"
                        },
                        {
                            "value": "centralindia",
                            "text": "Central India"
                        },
                        {
                            "value": "eastasia",
                            "text": "East Asia"
                        },
                        {
                            "value": "japaneast",
                            "text": "Japan East"
                        },
                        {
                            "value": "jioindiawest",
                            "text": "Jio India West"
                        },
                        {
                            "value": "koreacentral",
                            "text": "Korea Central"
                        },
                        {
                            "value": "canadacentral",
                            "text": "Canada Central"
                        },
                        {
                            "value": "francecentral",
                            "text": "France Central"
                        },
                        {
                            "value": "germanywestcentral",
                            "text": "Germany West Central"
                        },
                        {
                            "value": "norwayeast",
                            "text": "Norway East"
                        },
                        {
                            "value": "switzerlandnorth",
                            "text": "Switzerland North"
                        },
                        {
                            "value": "uaenorth",
                            "text": "UAE North"
                        },
                        {
                            "value": "brazilsouth",
                            "text": "Brazil South"
                        },
                        {
                            "value": "centralusstage",
                            "text": "Central US (Stage)"
                        },
                        {
                            "value": "eastusstage",
                            "text": "East US (Stage)"
                        },
                        {
                            "value": "eastus2stage",
                            "text": "East US 2 (Stage)"
                        },
                        {
                            "value": "northcentralusstage",
                            "text": "North Central US (Stage)"
                        },
                        {
                            "value": "southcentralusstage",
                            "text": "South Central US (Stage)"
                        },
                        {
                            "value": "westusstage",
                            "text": "West US (Stage)"
                        },
                        {
                            "value": "westus2stage",
                            "text": "West US 2 (Stage)"
                        },
                        {
                            "value": "asia",
                            "text": "Asia"
                        },
                        {
                            "value": "asiapacific",
                            "text": "Asia Pacific"
                        },
                        {
                            "value": "australia",
                            "text": "Australia"
                        },
                        {
                            "value": "brazil",
                            "text": "Brazil"
                        },
                        {
                            "value": "canada",
                            "text": "Canada"
                        },
                        {
                            "value": "europe",
                            "text": "Europe"
                        },
                        {
                            "value": "france",
                            "text": "France"
                        },
                        {
                            "value": "germany",
                            "text": "Germany"
                        },
                        {
                            "value": "global",
                            "text": "Global"
                        },
                        {
                            "value": "india",
                            "text": "India"
                        },
                        {
                            "value": "japan",
                            "text": "Japan"
                        },
                        {
                            "value": "korea",
                            "text": "Korea"
                        },
                        {
                            "value": "norway",
                            "text": "Norway"
                        },
                        {
                            "value": "southafrica",
                            "text": "South Africa"
                        },
                        {
                            "value": "switzerland",
                            "text": "Switzerland"
                        },
                        {
                            "value": "uae",
                            "text": "United Arab Emirates"
                        },
                        {
                            "value": "uk",
                            "text": "United Kingdom"
                        },
                        {
                            "value": "unitedstates",
                            "text": "United States"
                        },
                        {
                            "value": "unitedstateseuap",
                            "text": "United States EUAP"
                        },
                        {
                            "value": "eastasiastage",
                            "text": "East Asia (Stage)"
                        },
                        {
                            "value": "southeastasiastage",
                            "text": "Southeast Asia (Stage)"
                        },
                        {
                            "value": "centraluseuap",
                            "text": "Central US EUAP"
                        },
                        {
                            "value": "eastus2euap",
                            "text": "East US 2 EUAP"
                        },
                        {
                            "value": "westcentralus",
                            "text": "West Central US"
                        },
                        {
                            "value": "southafricawest",
                            "text": "South Africa West"
                        },
                        {
                            "value": "australiacentral",
                            "text": "Australia Central"
                        },
                        {
                            "value": "australiacentral2",
                            "text": "Australia Central 2"
                        },
                        {
                            "value": "australiasoutheast",
                            "text": "Australia Southeast"
                        },
                        {
                            "value": "japanwest",
                            "text": "Japan West"
                        },
                        {
                            "value": "jioindiacentral",
                            "text": "Jio India Central"
                        },
                        {
                            "value": "koreasouth",
                            "text": "Korea South"
                        },
                        {
                            "value": "southindia",
                            "text": "South India"
                        },
                        {
                            "value": "westindia",
                            "text": "West India"
                        },
                        {
                            "value": "canadaeast",
                            "text": "Canada East"
                        },
                        {
                            "value": "francesouth",
                            "text": "France South"
                        },
                        {
                            "value": "germanynorth",
                            "text": "Germany North"
                        },
                        {
                            "value": "norwaywest",
                            "text": "Norway West"
                        },
                        {
                            "value": "switzerlandwest",
                            "text": "Switzerland West"
                        },
                        {
                            "value": "ukwest",
                            "text": "UK West"
                        },
                        {
                            "value": "uaecentral",
                            "text": "UAE Central"
                        },
                        {
                            "value": "brazilsoutheast",
                            "text": "Brazil Southeast"
                        }
                    ]
                }
            ],
            "title": ""
        },
        {
            "name": "Azure Core Setup",
            "elements": [
                {
                    "type": "text",
                    "name": "root_id",
                    "hideNumber": true,
                    "title": "Resource prefix (Root ID)",
                    "isRequired": true,
                    "validators": [
                        {
                            "type": "text",
                            "maxLength": 24
                        }
                    ],
                    "maxLength": 24
                },
                {
                    "type": "radiogroup",
                    "name": "single_subscription",
                    "hideNumber": true,
                    "title": "Platform subscription options",
                    "choices": [
                        {
                            "value": "false",
                            "text": "Dedicated (recommended)"
                        },
                        {
                            "value": "true",
                            "text": "Single"
                        }
                    ]
                }
            ],
            "title": "",
            "description": "Azure Landing Zones will create the management group hierarchy under the Tenant Root Group with the prefix provided at this step."
        },
        {
            "name": "Platform management, security, and governance",
            "elements": [
                {
                    "type": "radiogroup",
                    "name": "log_analytics_enabled",
                    "hideNumber": true,
                    "title": "Deploy Log Analytics workspace and enable monitoring for your platform and resources",
                    "choices": [
                        {
                            "value": "true",
                            "text": "Yes (Recommended)"
                        },
                        {
                            "value": "false",
                            "text": "No"
                        }
                    ]
                },
                {
                    "type": "text",
                    "name": "log_analytics_retention",
                    "visibleIf": "{log_analytics_enabled} = true",
                    "hideNumber": true,
                    "title": "Log Analytics Retention",
                    "enableIf": "{log_analytics_enabled} = true",
                    "requiredIf": "{log_analytics_enabled} = true",
                    "validators": [
                        {
                            "type": "numeric"
                        }
                    ]
                },
                {
                    "type": "text",
                    "name": "management_group_id",
                    "hideNumber": true,
                    "title": "Management Group ID"
                }
            ],
            "title": ""
        }
    ]
};