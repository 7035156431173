import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';

export default function Projects() {

    return (
        <>
            <Jumbotron>
                <h1>List of notable projects</h1>
                <p>
                    This page will list out some notable projects that I have worked on over the years. 
                    In addition to the description, I will also work on getting architecture diagrams and references for each.
                </p>
            </Jumbotron>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Mobile Finance Application
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>Worked with a development team to architect build a mobile infrastructure backend using Azure App Services, Function Apps, Azure SQL Failover Groups. 
                            This consisted of a SPA hosted on the app service and an API hosted on a function app to act as the database interface. One app stack was hosted in East US while the other was in Central US.
                            Each component talked to eachother through private endpoint connections with some DNS magic. 
                            Each region's VNET used its own set of private DNS zones so that the SQL write endpoint and read endpoint would remain the same even during a failover.
                            All code deployments were handled through Azure DevOps and a self-hosted pool for private connectivity.
                            The key was to this project was to acheive a PCI compliant isolated environment for mobile application response and delivery.
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                            Project 2
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blahblah blah blah blah
                            blah blahblah blahblah blahblah blahblah blahblah blahblah blahblah blahblah blahblah blahblah blahblah blahblah
                            blah blahblah blahblah blahblah blahblah blahblah blahblah blah</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                            Project 3
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blahblah blah blah blah
                            blah blahblah blahblah blahblah blahblah blahblah blahblah blahblah blahblah blahblah blahblah blahblah blahblah
                            blah blahblah blahblah blahblah blahblah blahblah blahblah blah</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    )
}