import React from 'react';
import { HashRouter as Router, Routes, Route }
        from 'react-router-dom';
import './App.css';
import Navi from './Navbar';
import Home from './pages/Home'
import About from './pages/About';
import AboutThis from './pages/AboutThis';
import Projects from './pages/Projects';
import SurveyComponent from './pages/Accelerator';


export default function App()  {
  return (
    <div className="App">
      <Navi />
      <Router>
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='about' element={<About/>} />
            <Route path='projects/about-this' element={<AboutThis/>} />
            <Route path='projects' element={<Projects/>} />
            <Route path='accelerator/azure' element={<SurveyComponent/>} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
        </Routes>
      </Router>
    </div>
  )
}