import React from 'react'
import Container from 'react-bootstrap/Container';
import { Nav, Navbar, NavDropdown }  from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function Navi() {
    return (
        <Navbar collapseOnSelect bg="light" expand="lg" variant="light">
          <Container>
            <Navbar.Brand href="/">Cloud Portfolio</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {/*<Nav.Link href="#/about">About</Nav.Link>*/}
                <NavDropdown title="Projects" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#/projects/about-this">
                    About This Site
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/projects">
                    Projects
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Accelerator" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#/accelerator/azure">
                    Azure Landing Zone
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
}

export default Navi