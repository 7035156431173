import React from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron';
import { SocialIcon } from 'react-social-icons';

const hostname = window && window.location && window.location.hostname;

export default function Home() {
    const logo_size = "110"
    //const az_sae = 


    return (
        <>
        <Jumbotron>
            <h1>Thomas Edwards</h1>
            <p>Your everyday Cloud Architect.</p>
            <p>This site is hosted on {hostname}</p>
            <div></div>
            <div className="social" >
                <SocialIcon url="https://github.com/73dward5" />
            </div>
            <div className="social" >
                <SocialIcon url="https://www.linkedin.com/in/edwardsitj/" />
            </div>
            <div className="social" >
                <SocialIcon url="https://www.credly.com/users/edwards-itj/badges" />
            </div>
        </Jumbotron>
        <div className="logos" style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <img src={'https://images.credly.com/size/'+logo_size+'x'+logo_size+'/images/336eebfc-0ac3-4553-9a67-b402f491f185/azure-administrator-associate-600x600.png'} 
            alt="Azure Administrator Associtate"></img>
            <img src={'https://images.credly.com/size/'+logo_size+'x'+logo_size+'/images/987adb7e-49be-4e24-b67e-55986bd3fe66/azure-solutions-architect-expert-600x600.png'}
            alt="Azure Solutions Architect Expert"></img>
            <img src={'https://api.accredible.com/v1/frontend/credential_website_embed_image/badge/59389451'}
            alt="Google Cloud Professional Cloud Architect"></img>
            <img src={'https://images.credly.com/size/'+logo_size+'x'+logo_size+'/images/0e284c3f-5164-4b21-8660-0d84737941bc/image.png'}
            alt="AWS Solutions Architect Associate"></img>
            <img src={'https://images.credly.com/size/'+logo_size+'x'+logo_size+'/images/e0934673-80f9-4aa2-bee4-0d3b295939e8/vmware_Milestone_2xVCP_DCVNV.png'}
            alt="VMware Double VCP"></img>
            <img src={'https://images.credly.com/size/'+logo_size+'x'+logo_size+'/images/8b8ed108-e77d-4396-ac59-2504583b9d54/cka_from_cncfsite__281_29.png'}
            alt="Certified Kubernetes Administrator"></img>
            <img src={'https://images.credly.com/size/'+logo_size+'x'+logo_size+'/images/99289602-861e-4929-8277-773e63a2fa6f/image.png'}
            alt="Terraform Associate"></img>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        </>
    );
}