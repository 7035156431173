import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import vcp_nv from '../images/vcp-nv.png';
import vcp_dcv from '../images/vcp-dcv.png';
//import az_sae from '../images/az-sae.png';
//import aws_saa from '../images/aws-saa.png';
import cka from '../images/cka.png';
import tf_002 from '../images/tf-002.png';

export default function About() {
    return (
        <>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Certifications
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Table striped bordered condensed hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Badge</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Azure Solutions Architect Expert (AZ-303/AZ-304)</td>
                                        <td>
                                
                                               
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>AWS Solutions Architect Associate (AWS-SAA)</td>
                                        <td> 
                              
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Google Professional Cloud Architect (GCP-PCA)</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Certified Kubernetes Administrator (CKA)</td>
                                        <td><div data-src={cka}></div></td>
                                    </tr>
                                    <tr>
                                        <td>VMware Certified Professional - Network Vitualization (VCP-NV)</td>
                                        <td><div data-src={vcp_nv}></div></td>
                                    </tr>
                                    <tr>
                                        <td>VMware Certified Professional - Datacenter Virtualization (VCP-DCV)</td>
                                        <td><div data-src={vcp_dcv}></div></td>
                                    </tr>
                                    <tr>
                                        <td>Terraform Associate (002)</td>
                                        <td><div data-src={tf_002}></div></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};
